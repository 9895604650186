@tailwind base;
@tailwind components;
@tailwind utilities;

.fullscreen-video::-webkit-media-controls {
  display: none !important;
}

.fullscreen-video::-webkit-media-controls-enclosure {
  display: none !important;
}

.fullscreen-video::-webkit-media-controls-panel {
  display: none !important;
}

.fullscreen-video::-webkit-media-controls-overlay-play-button {
  display: none !important;
}

.fullscreen-video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

button::-webkit-media-controls-fullscreen-button {
  display: none !important;
}

.video-no-fullscreen::-webkit-media-controls-fullscreen-button {
  display: none !important;
}
.video-no-fullscreen::-webkit-media-controls {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

#video-container:fullscreen video {
  height: 100vh;
  width: 100vw;
}

#video-container:fullscreen .fullscreen-button {
  display: none !important;
}

.swiper-button-disabled {
  pointer-events: all !important;
}