/*GENERAL*/
body {
  background-color: #0d0e10;
  color: white;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
}

.container {
  margin: 0 auto;
}

.btn-default-b-white, .btn-default-b-red {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: 1px solid;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.25rem;
  background: rgba(0, 0, 0, 0.5);
}
.btn-default-b-white ion-icon, .btn-default-b-red ion-icon {
  font-size: 20px;
  padding-right: 5px;
}

.btn-default-b-red {
  border: 2px solid;
  border-color: #ff0000;
}
.btn-default-b-red:hover {
  background: #ff0000;
}

.btn-default-b-white {
  border-color: white;
  /* &:focus {
    background: inherit;
    color: white;
  } */
}
.btn-default-b-white:hover {
  background: white;
  color: black;
}

ion-icon {
  pointer-events: none;
}

.icon-super-like {
  background-image: url(/public/images/icons/icon-super-like.png);
  height: 21px;
  width: 24px;
}

.border-title {
  border-bottom: 2px solid red;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.text-promo {
  font-family: "Bebas Neue", cursive;
  font-size: 24px;
}

.icon-only-fans {
  background-image: url(/public/images/icons/icon-super-like.png);
  height: 21px;
  width: 24px;
}

.accent-palenque-red-contrast {
  accent-color: #ff0000;
}

header {
  z-index: 3;
  height: 73px;
  background: black;
}
header .container {
  height: 73px;
  position: relative;
}
header .container .menu-links a {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
}
header .container .menu-links a.active {
  color: #ff0000;
}
header .container .menu-logo {
  padding-left: 5px;
}
header .container .menu-icons {
  /*Dropdown Menu*/
}
header .container .menu-icons .icon-button {
  border-radius: 50%;
  transition: filter 300ms;
}
header .container .menu-icons .icon-button:hover {
  filter: brightness(1.2);
}
header .container .menu-icons .dropdown {
  position: absolute;
  top: 53px;
  width: 300px;
  background-color: #242526;
  border: 1px solid #474a4d;
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;
  transition: height 500ms ease;
}
header .container .menu-icons .dropdown .menu-item {
  height: 45px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: background 500ms;
  padding: 0.5rem;
  font-weight: normal !important;
  font-size: 13px !important;
}
header .container .menu-icons .dropdown .menu-item:hover {
  background-color: #525357;
}

.main {
  margin-top: 73px;
}

.swiper {
  width: 100%;
  height: 100%;
}
.swiper .swiper-button-next,
.swiper .swiper-button-prev {
  color: white;
}
.swiper .swiper-slide {
  text-align: center;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper .swiper-slide img.img-banner {
  display: block;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.home-banner .wrap-info-serie {
  position: absolute;
}
.home-banner .wrap-info-serie .info-serie {
  width: 42%;
  max-width: 540px;
}
.home-banner .wrap-info-serie .info-serie img {
  max-width: 360px;
}
.home-banner .wrap-info-serie .info-serie p {
  text-align: left;
}
.home-banner .wrap-info-serie .info-serie .info-serie-buttons {
  max-width: 385px;
  font-size: 14px;
}
.home-banner .wrap-info-serie .info-serie .info-serie-buttons .info-serie-categ {
  text-transform: uppercase;
}
.home-banner .wrap-info-serie .info-serie .info-serie-buttons .info-serie-categ span {
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  border-radius: 0.5rem;
  line-height: 1.25rem;
}
.home-banner .wrap-info-serie .info-serie .info-serie-buttons .text-sm {
  font-size: 13px;
}
.home-banner img.img-banner {
  aspect-ratio: 234/83;
}
.home-banner .swiper-pagination-bullet {
  background: rgb(102, 102, 102);
  opacity: inherit;
}
.home-banner .swiper-pagination-bullet-active {
  background: white;
}

/*THUMBVIDEO*/
.thumb-video {
  width: 100%;
}
.thumb-video img.thumb-background {
  width: 100%;
  aspect-ratio: 70/39;
  box-shadow: inset 0 0 10px #fff;
}
.thumb-video .slide-logo {
  position: absolute;
  width: 35%;
  bottom: 5px;
  left: 5px;
}
.thumb-video .wrap-label {
  position: absolute;
  top: 7px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}
.thumb-video .wrap-label .label-video {
  text-transform: uppercase;
  padding: 4px 5px 2px 5px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: bold;
}
.thumb-video .wrap-label .label-video.free {
  background: white;
  color: #ff0000;
}
.thumb-video .wrap-label .label-video.member {
  background: #ff0000;
  color: white;
}
.thumb-video .wrap-label .label-video.premiere {
  background: rgba(0, 0, 0, 0.4);
  border: 1px #ff0000 solid;
}
.thumb-video .slide-play {
  position: absolute;
  border: solid 2px #ff0000;
  border-radius: 50%;
  display: flex;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 3%;
}
.thumb-video .slide-play ion-icon {
  font-size: 32px;
}
.thumb-video .slide-block {
  position: absolute;
  display: flex;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  padding: 10px;
}

.slide-title {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
.slide-title span.title-play ion-icon {
  font-size: 24px;
  border: 2px solid #ff0000;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
}

/*THUMBSERIE*/
.thumb-serie {
  width: 100%;
  height: 100%;
}
.thumb-serie img.thumb-background {
  width: 100%;
  height: 100%;
  aspect-ratio: 211/136;
  box-shadow: inset 0 0 10px #fff;
}
.thumb-serie .slide-logo {
  position: absolute;
  width: 60%;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%);
}
.thumb-serie .label-free {
  text-transform: uppercase;
  position: absolute;
  top: 5px;
  background: #ff0000;
  padding: 3px 5px;
  border-radius: 6px;
  font-size: 12px;
  left: 5px;
}

/*SLIDE SERIES*/
.slide-series {
  position: relative;
}
.slide-series .title {
  align-items: center;
}
.slide-series .title h3 {
  text-transform: uppercase;
  font-weight: bold;
}
.slide-series .title a {
  font-size: 13px;
  line-height: 20px;
  min-width: 128px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.slide-series .slide-container {
  width: 100%;
}
.slide-series .slide-container img {
  width: 100%;
}
.slide-series .slide-container .slide-image {
  position: relative;
}
.slide-series .slide-container .slide-image .slide-logo {
  position: absolute;
  width: 35%;
  bottom: 5px;
  left: 5px;
}
.slide-series .slide-container .slide-image .label-free {
  text-transform: uppercase;
  position: absolute;
  top: 5px;
  background: #ff0000;
  padding: 3px 5px;
  border-radius: 6px;
  font-size: 12px;
  left: 5px;
}
.slide-series .slide-container .slide-image .slide-play {
  position: absolute;
  border: solid 2px #ff0000;
  border-radius: 50%;
  display: flex;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 50%;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 10px;
}
.slide-series .slide-container .slide-image .slide-play ion-icon {
  font-size: 32px;
}
.slide-series .slide-container .slide-title {
  display: flex;
  /* justify-content: center; */
  align-items: center;
  margin-top: 10px;
}
.slide-series .slide-container .slide-title span.title-play ion-icon {
  font-size: 24px;
  border: 2px solid #ff0000;
  border-radius: 50%;
  padding: 5px;
  margin-right: 10px;
}
.slide-series .swiper-button-next,
.slide-series .swiper-button-prev {
  top: calc(50% - 25px);
}
.slide-series .separator {
  position: absolute;
  bottom: -1rem;
  width: 100%;
  left: -2rem;
}

/*SECTION DOWNLOAD*/
.section-download .container {
  align-items: center;
}
.section-download .container .section-text {
  width: 50%;
}
.section-download .container .section-text .qr-code {
  justify-content: space-between;
}
.section-download .container .section-text .app-images {
  justify-content: space-between;
}
.section-download .container .section-image {
  width: 50%;
  margin-left: 10px;
}
.section-download .container .section-image img {
  padding: 10px;
  margin: 0 auto;
}

/*SECTION DONATION*/
.section-donation .container {
  align-items: center;
}
.section-donation .container .text-donation {
  width: 60%;
}
.section-donation .container .button-donation {
  width: 40%;
  text-align: right;
}

/*SECTION FOOTER*/
.section-footer .container {
  justify-content: space-between;
}

/*DEFAULT PAGE*/
.default-page {
  max-width: 1180px;
}
.default-page h1,
.default-page h2 {
  text-transform: uppercase;
  font-weight: bold;
}

/*ESTRENOS PAGE*/
.container-estrenos .wrap-estrenos .wrap-thumb {
  width: 50%;
  margin-right: 10px;
}
.container-estrenos .wrap-estrenos .info-estrenos {
  width: 50%;
  margin: 10px;
}
.container-estrenos .wrap-estrenos .info-estrenos .first-section .actions ion-icon {
  font-size: 22px;
}
.container-estrenos .wrap-estrenos:not(:last-child) {
  border-bottom: 1px solid;
  padding-bottom: 1rem;
}

/*SERIES PAGE*/
.container-series .container-video {
  width: 100%;
}

/*LOGIN PAGE*/
.container-login .facebook ion-icon {
  font-size: 24px;
  margin-right: 5px;
  color: #067aea;
}
.container-login .google ion-icon {
  font-size: 24px;
  margin-right: 5px;
  color: rgb(255, 61, 0);
}
.container-login .text-other-social {
  left: 50%;
  transform: translate(-50%, -50%);
  background: #0d0e10;
  padding: 0 20px;
}
.container-login button {
  font-size: 14px;
}

/*SPONSORS PAGE*/
.container-sponsors .btn-default-b-red {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}
.container-sponsors .video-wrapper .video-background {
  width: 100%;
  height: 100vh;
  min-height: 600px;
  max-height: 56vw;
}
.container-sponsors .video-wrapper .wrap-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.container-sponsors .video-wrapper .container {
  display: flex;
  align-items: center;
  height: 100%;
}
.container-sponsors .video-wrapper .container .wrap-form-sponsors {
  width: 420px;
  background: rgba(0, 0, 0, 0.6);
  padding: 20px;
  border-radius: 20px;
}
.container-sponsors .video-wrapper .container .wrap-form-sponsors form {
  display: flex;
  flex-direction: column;
}
.container-sponsors .video-wrapper .container .wrap-form-sponsors form p {
  font-size: 15px;
  margin-bottom: 10px;
  margin-top: 5px;
}
.container-sponsors .how-do .container .video {
  width: 60%;
}
.container-sponsors .how-do .container .how-do-section {
  width: 40%;
}
.container-sponsors .how-do .container .how-do-section h3 {
  margin-bottom: 15px;
}
.container-sponsors .how-do .container .how-do-section .how-do-text {
  width: 320px;
}
.container-sponsors .how-do .container .how-do-section .how-do-text ion-icon {
  color: #ff0000;
  margin-right: 15px;
}
.container-sponsors .banner-two {
  background-image: url(/public/images/sponsors-banner.jpg);
  background-size: cover;
  height: 100vh;
  min-height: 600px;
  background-position: bottom;
}
.container-sponsors .banner-two .box {
  background: rgba(0, 0, 0, 0.6);
  border-radius: 10px;
  max-width: 800px;
  padding: 40px 60px;
  text-align: center;
}
.container-sponsors .banner-two .box .banner-two-text {
  line-height: 1.7;
  margin-bottom: 25px;
  margin-top: 10px;
}
.container-sponsors .banner-two .box .banner-two-text span {
  background: #ff0000;
  font-weight: bold;
  padding: 2px 5px;
  border-radius: 10px;
  font-size: 1.5em;
}
.container-sponsors .sponsors-social-media h2 {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}
.container-sponsors .sponsors-social-media .container .media-element {
  text-align: center;
}
.container-sponsors .sponsors-social-media .container .media-element img {
  height: 240px;
  margin: 0 auto 10px;
}
.container-sponsors .sponsors-social-media .container .media-element .fallow {
  background: #ff0000;
  font-size: 32px;
  font-weight: bold;
  line-height: 25px;
  padding-top: 5px;
  width: 120px;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 5px;
}
.container-sponsors .sponsors-social-media .container .media-element .fallow span {
  display: block;
  font-size: 14px;
}
.container-sponsors .sponsors-clients .swiper-wrapper {
  align-items: center;
}

/*DONACIONES PAGE*/
.container-donation .wrap-donation {
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 0px 5px 10px 0px rgba(255, 255, 255, 0.7);
  border-radius: 20px;
}
.container-donation .wrap-donation:hover {
  transform: translateY(-5px);
  box-shadow: 0px 10px 20px 2px rgba(255, 255, 255, 0.7);
}
.container-donation .wrap-donation .up-sec {
  background-image: url(/public/images/banner-donaciones-desk.png);
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: 0;
  height: 288px;
  padding-left: 30px;
}
.container-donation .wrap-donation .options .wrap-options .option-default select {
  min-width: 200px;
  width: 90%;
  max-width: 400px;
  color: #fff;
  background-color: #000;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5px;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: block;
  background-image: none;
  border: 1px solid;
  word-break: normal;
}
.container-donation .wrap-donation .options .wrap-select:after {
  content: ">";
  font: 17px "Consolas", monospace;
  color: #fff;
  transform: rotate(90deg);
  right: 30px;
  top: 10px;
  padding: 0 0 2px;
  position: absolute;
  pointer-events: none;
}

/*CONTACT PAGE*/
.container-contact {
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
}
.container-contact .button-actions {
  max-width: 600px;
  margin: 0 auto;
  justify-content: space-evenly;
}
.container-contact .button-actions button,
.container-contact .button-actions a {
  width: 100%;
  max-width: 200px;
}
.container-contact .button-actions .btn-default-b-red ion-icon,
.container-contact .button-actions .btn-default-b-white ion-icon {
  padding-right: 10px;
}
.container-contact form {
  max-width: 460px;
  margin: 0 auto;
}

/*PLAYER PAGE*/
.container-player .actions-buttons .loading-button-section,
.container-series .actions-buttons .loading-button-section,
.info-serie-buttons .actions-buttons .loading-button-section {
  width: 155px;
}
.container-player .actions-buttons .loading-button-section button,
.container-series .actions-buttons .loading-button-section button,
.info-serie-buttons .actions-buttons .loading-button-section button {
  width: 100%;
}
.container-player .actions-buttons .btn-default-b-white.loading-button,
.container-series .actions-buttons .btn-default-b-white.loading-button,
.info-serie-buttons .actions-buttons .btn-default-b-white.loading-button {
  /* height: 38px; */
}
.container-player .actions-buttons .btn-default-b-white.loading-button div,
.container-series .actions-buttons .btn-default-b-white.loading-button div,
.info-serie-buttons .actions-buttons .btn-default-b-white.loading-button div {
  height: 100%;
}
.container-player .actions-buttons .btn-default-b-white.loading-button div svg,
.container-series .actions-buttons .btn-default-b-white.loading-button div svg,
.info-serie-buttons .actions-buttons .btn-default-b-white.loading-button div svg {
  /* height: 100%; */
  margin-right: 0;
}

.container-player .dropdown {
  position: absolute;
  max-width: 400px;
  background-color: #000;
  border: 1px solid white;
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;
  transition: height 500ms ease;
}
.container-player .dropdown .buttons-select-donation button {
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
  border-width: 2px;
  width: 85px;
}
.container-player .dropdown .buttons-select-donation button.active {
  background: white;
  color: black;
}
.container-player .dropdown .buttons-select-donation button.active .icon-super-like {
  background-image: url(/public/images/icons/icon-super-like-negro.png);
}

/*.container-player .dropdown:before {
  content: "";
  width: 0;
  height: 0;
  font-size: 0;
  overflow: hidden;
  display: block;
  border-width: 15px;
  border-color: #a7a7db transparent transparent transparent;
  border-style: solid dashed dashed dashed;
}*/
/*MEMBERSHIP*/
.container-member .thumb-serie {
  height: auto;
}
.container-member .thumb-serie img {
  aspect-ratio: auto;
}

.modal-membership .container-modal .membership-content ul li {
  margin-bottom: 3px;
}
.modal-membership .container-modal hr {
  width: calc(100% + 48px);
  left: -24px;
  position: relative;
  height: 2px;
  background: white;
}
.modal-membership .serie-back {
  width: calc(100% + 3rem);
  margin-left: -1.5rem;
}

.modal-coins .coins-select button.active {
  background: white;
  color: black;
}

@media screen and (max-width: 1023px) {
  header .container {
    margin: 0;
    max-width: 100%;
  }
  header .container .menu-links {
    background: rgba(0, 0, 0, 0.9);
    text-align: center;
  }
  /*SECTION DOWNLOAD*/
  .section-download .container .section-text .qr-code img {
    max-width: 180px;
  }
  .section-download .container .section-text .app-images img {
    max-width: 180px;
  }
  /*SPONSORS PAGE*/
  .container-sponsors .video-wrapper .video-background {
    height: 56vw;
    min-height: auto;
  }
  .container-sponsors .video-wrapper .wrap-container {
    position: relative;
  }
  .container-sponsors .video-wrapper .wrap-container .container {
    text-align: center;
    justify-content: center;
  }
  .container-sponsors .how-do .container .video {
    width: 100%;
  }
  .container-sponsors .how-do .container .how-do-section {
    width: 100%;
  }
  .container-sponsors .how-do .container .how-do-section .how-do-text {
    width: 100%;
  }
  .container-sponsors .banner-two {
    background-image: url(/public/images/sponsors-banner-movil.jpg);
    height: 460px;
    min-height: auto;
  }
  .container-sponsors .banner-two .box {
    padding: 0;
    background: transparent;
  }
  /*PLAYER PAGE*/
  .container-player .first-line {
    padding-bottom: 0.5rem;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;
  }
  .container-player .first-line button {
    flex-direction: row;
  }
}
@media screen and (max-width: 767px) {
  .actions-buttons .btn-default-b-white:hover {
    background: inherit;
    color: inherit;
  }
  .container-series .actions-buttons {
    flex-direction: column;
  }
  .container-series .actions-buttons .play-button .action-video-button {
    flex-direction: row;
    border: 2px solid;
    border-color: #ff0000;
    max-width: 460px;
    margin: 0 auto;
  }
  .action-video-button {
    flex-direction: column;
    padding: 6px;
    border: 0;
    background: transparent;
  }
  .action-video-button ion-icon {
    font-size: 24px;
  }
  .action-video-button div {
    text-transform: capitalize;
    font-weight: normal;
  }
  .home-banner {
    height: calc(100vh - 73px);
    max-height: 152vw;
  }
  .home-banner .wrap-info-serie {
    height: 100%;
  }
  .home-banner .wrap-info-serie .info-serie {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding-bottom: 20px;
  }
  .home-banner .wrap-info-serie .info-serie .info-serie-buttons {
    width: 100%;
    max-width: 100%;
  }
  .home-banner .wrap-info-serie .info-serie .info-serie-buttons .info-serie-categ {
    justify-content: center;
  }
  .home-banner .wrap-info-serie .info-serie .info-serie-buttons .info-serie-categ .estreno-capitulo {
    border: 1px solid #ff0000;
  }
  .home-banner .wrap-info-serie .info-serie .info-serie-buttons .actions-buttons-mob {
    display: flex;
    justify-content: space-between;
  }
  .home-banner .wrap-info-serie .info-serie .info-serie-buttons .actions-buttons-mob .btn-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .home-banner .wrap-info-serie .info-serie .info-serie-buttons .actions-buttons-mob .btn-mob ion-icon {
    font-size: 24px;
  }
  .home-banner .wrap-info-serie .info-serie img {
    max-width: 260px;
    margin: 0 auto;
  }
  .home-banner .wrap-info-serie .info-serie p {
    text-align: center;
  }
  .home-banner img.img-banner {
    aspect-ratio: 40/61;
  }
  .info-serie-buttons .actions-buttons .btn-default-b-white.loading-button {
    height: 45px;
    padding: 5px;
  }
  .info-serie-buttons .actions-buttons button {
    padding: 0;
  }
  .text-promo {
    font-size: 18px;
  }
  /*SLIDE SERIES*/
  .slide-series .swiper-button-next,
.slide-series .swiper-button-prev {
    display: none;
  }
  /*SECTION DOWNLOAD*/
  .section-download .container {
    flex-direction: column;
    position: relative;
  }
  .section-download .container .section-text {
    width: 100%;
  }
  .section-download .container .section-text .app-images {
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 15px;
  }
  .section-download .container .section-text .app-images img {
    max-width: inherit;
  }
  .section-download .container .section-image {
    width: 100%;
    margin-left: 0;
  }
  .section-download .container .section-image img {
    padding: 20px;
  }
  /*SECTION DONATION*/
  .section-donation .container {
    flex-direction: column;
  }
  .section-donation .container .text-donation {
    width: 100%;
  }
  .section-donation .container .button-donation {
    width: 100%;
    text-align: center;
  }
  /*SECTION FOOTER*/
  .section-footer .container ul {
    justify-content: center;
  }
  /*ESTRENOS PAGE*/
  .container-estrenos .wrap-estrenos .wrap-thumb {
    width: 100%;
    margin: 0;
  }
  .container-estrenos .wrap-estrenos .info-estrenos {
    width: 100%;
    margin: 0;
  }
  /*SERIE PAGE*/
  .btn-default-b-red ion-icon, .btn-default-b-white ion-icon {
    padding-right: 0px;
  }
  /*SPONSORS PAGE*/
  .container-sponsors .video-wrapper .video-background {
    height: 56vw;
    min-height: auto;
  }
  .container-sponsors .video-wrapper .wrap-container {
    position: relative;
  }
  .container-sponsors .video-wrapper .wrap-container .container {
    text-align: center;
    justify-content: center;
  }
  .container-sponsors .sponsors-social-media .container .media-element img {
    padding-top: 0px !important;
    height: auto;
  }
  /*DONACIONES PAGE*/
  .container-donation .wrap-donation .up-sec {
    background-image: url(/public/images/banner-donaciones-mob.png);
  }
  /*CONTACT PAGE*/
  .container-contact .button-actions {
    align-items: center;
    flex-direction: column;
  }
  .container-contact .button-actions .btn-default-b-red ion-icon,
.container-contact .button-actions .btn-default-b-white ion-icon {
    padding-right: 10px;
  }
  /*PLAYER PAGE*/
  .container-player .actions-buttons .loading-button-section,
.container-series .actions-buttons .loading-button-section {
    width: 75px;
  }
  .container-player .actions-buttons .btn-default-b-white.loading-button,
.container-series .actions-buttons .btn-default-b-white.loading-button {
    /*  height: 56px; */
    height: 100%;
    width: 100%;
    padding: 8px;
    /* width: 88px; */
  }
  .container-player .dropdown,
.container-series .dropdown {
    padding: 1rem 0.75rem;
  }
  .container-player .dropdown .buttons-select-donation button,
.container-series .dropdown .buttons-select-donation button {
    width: 75px;
  }
  /*MEMBERSHIP*/
  .modal-membership .container-modal hr {
    width: calc(100% + 24px);
    left: -12px;
    position: relative;
    height: 2px;
    background: white;
  }
  .modal-membership .serie-back {
    width: calc(100% + 1.5rem);
    margin-left: -0.75rem;
  }
}
@media screen and (max-width: 640px) {
  /*SLIDE SERIES*/
  .slide-series .title .btn-default-b-white {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.35rem;
    padding-bottom: 0.35rem;
  }
  .slide-series .swiper {
    /*FIX SLIDE 100%*/
    /* left: -0.75rem;
    padding-left: 0.75rem;
    width: calc(100% + 1.5rem); */
  }
}
@media screen and (max-width: 520px) {
  header .container .menu-logo img {
    height: 30px !important;
  }
}
@media screen and (max-width: 380px) {
  .container-player .actions-buttons .loading-button-section,
.container-series .actions-buttons .loading-button-section {
    width: 60px;
  }
  .container-player .actions-buttons .font-sm-buttons,
.container-series .actions-buttons .font-sm-buttons {
    font-size: 10px;
  }
  .container-player .dropdown .buttons-select-donation button {
    width: 70px;
  }
}
@media screen and (max-width: 360px) {
  .home-banner .wrap-info-serie .info-serie .info-serie-buttons .actions-buttons-mob {
    /* .btn-mob {
      font-size: 12px;
    } */
  }
  .home-banner .wrap-info-serie .info-serie .info-serie-buttons .actions-buttons-mob .btn-default-b-red {
    font-size: 13px;
  }
  .container-sponsors .video-wrapper .container .wrap-form-sponsors form p {
    font-size: 13px;
  }
}
@media screen and (min-width: 1200px) {
  /*SECTION DOWNLOAD*/
  .section-download .container {
    max-width: 1000px;
  }
  /*SECTION DONATION*/
  .section-donation .container {
    max-width: 1180px;
  }
  /*SECTION FOOTER*/
  .section-footer .container {
    max-width: 1180px;
    align-items: center;
  }
}
@media screen and (min-width: 2000px) {
  .container-sponsors .video-wrapper .video-background {
    height: auto;
  }
  .container-sponsors .banner-two {
    max-height: 920px;
  }
}
.cookies-consents {
  font-size: 14px;
  line-height: 18px;
  background: #fff !important;
  color: #374151 !important;
  left: inherit !important;
  display: block !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.35);
  max-width: 400px;
  width: auto !important;
  right: 0;
  margin: 15px;
  border-radius: 2px;
}/*# sourceMappingURL=App.css.map */