$defaultBlackColor: #0d0e10;
$primary-red: #ff0000;

/*GENERAL*/
body {
  background-color: $defaultBlackColor;
  color: white;
  font-size: 16px;
  font-family: Montserrat, sans-serif;
}
.container {
  margin: 0 auto;
}
%btn-default {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  border: 1px solid;
  border-radius: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.25rem;
  ion-icon {
    font-size: 20px;
    padding-right: 5px;
  }
  background: rgba(0, 0, 0, 0.5);
}

.btn-default-b-red {
  @extend %btn-default;
  border: 2px solid;
  border-color: $primary-red;
  &:hover {
    background: $primary-red;
  }
}
.btn-default-b-white {
  @extend %btn-default;
  border-color: white;
  &:hover {
    background: white;
    color: black;
  }
  /* &:focus {
    background: inherit;
    color: white;
  } */
}

ion-icon {
  pointer-events: none;
}

.icon-super-like {
  background-image: url(/public/images/icons/icon-super-like.png);
  height: 21px;
  width: 24px;
}

.border-title {
  border-bottom: 2px solid red;
  max-width: fit-content;
}

.text-promo {
  font-family: "Bebas Neue", cursive;
  font-size: 24px;
}
.icon-only-fans {
  background-image: url(/public/images/icons/icon-super-like.png);
  height: 21px;
  width: 24px;
}

.accent-palenque-red-contrast {
  accent-color: $primary-red;
}

header {
  z-index: 3;
  height: 73px;
  background: black;
  .container {
    height: 73px;
    position: relative;
    .menu-links {
      a {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
      }
      a.active {
        color: $primary-red;
      }
    }
    .menu-logo {
      padding-left: 5px;
    }
    .menu-icons {
      .icon-button {
        border-radius: 50%;
        transition: filter 300ms;
        &:hover {
          filter: brightness(1.2);
        }
      }
      /*Dropdown Menu*/
      .dropdown {
        position: absolute;
        top: 53px;
        width: 300px;
        background-color: #242526;
        border: 1px solid #474a4d;
        border-radius: 8px;
        padding: 1rem;
        overflow: hidden;
        transition: height 500ms ease;
        .menu-item {
          height: 45px;
          display: flex;
          align-items: center;
          border-radius: 8px;
          transition: background 500ms;
          padding: 0.5rem;
          font-weight: normal !important;
          font-size: 13px !important;
          &:hover {
            background-color: #525357;
          }
        }
      }
    }
  }
}

.main {
  margin-top: 73px;
}

.swiper {
  width: 100%;
  height: 100%;
  .swiper-button-next,
  .swiper-button-prev {
    color: white;
  }

  .swiper-slide {
    text-align: center;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    img.img-banner {
      display: block;
      width: 100%;
      object-fit: cover;
    }
  }
}

.home-banner {
  .wrap-info-serie {
    position: absolute;
    .info-serie {
      width: 42%;
      max-width: 540px;
      img {
        max-width: 360px;
      }
      p {
        text-align: left;
      }
      .info-serie-buttons {
        max-width: 385px;
        font-size: 14px;
        .info-serie-categ {
          text-transform: uppercase;
          span {
            background: rgba(0, 0, 0, 0.6);
            padding: 5px 10px;
            border-radius: 0.5rem;
            line-height: 1.25rem;
          }
        }
        .text-sm {
          font-size: 13px;
        }
      }
    }
  }
  img.img-banner {
    aspect-ratio: 234/83;
  }
  .swiper-pagination-bullet {
    background: rgb(102, 102, 102);
    opacity: inherit;
  }
  .swiper-pagination-bullet-active {
    background: white;
  }
}

/*THUMBVIDEO*/
.thumb-video {
  width: 100%;
  img.thumb-background {
    width: 100%;
    aspect-ratio: 70/39;
    box-shadow: inset 0 0 10px #fff;
  }
  .slide-logo {
    position: absolute;
    width: 35%;
    bottom: 5px;
    left: 5px;
  }
  .wrap-label {
    position: absolute;
    top: 7px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0 5px;
    .label-video {
      text-transform: uppercase;
      padding: 4px 5px 2px 5px;
      border-radius: 6px;
      font-size: 12px;
      font-weight: bold;
    }
    .label-video.free {
      background: white;
      color: $primary-red;
    }
    .label-video.member {
      background: $primary-red;
      color: white;
    }
    .label-video.premiere {
      background: rgba(0, 0, 0, 0.4);
      border: 1px $primary-red solid;
    }
  }
  .slide-play {
    position: absolute;
    border: solid 2px $primary-red;
    border-radius: 50%;
    display: flex;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    background-color: rgba(0, 0, 0, 0.4);
    padding: 3%;
    ion-icon {
      font-size: 32px;
    }
  }
  .slide-block {
    position: absolute;
    display: flex;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    padding: 10px;
  }
}
.slide-title {
  display: flex;
  align-items: center;
  margin-top: 10px;
  span.title-play {
    ion-icon {
      font-size: 24px;
      border: 2px solid $primary-red;
      border-radius: 50%;
      padding: 5px;
      margin-right: 10px;
    }
  }
}

/*THUMBSERIE*/
.thumb-serie {
  width: 100%;
  height: 100%;
  img.thumb-background {
    width: 100%;
    height: 100%;
    aspect-ratio: 211/136;
    box-shadow: inset 0 0 10px #fff;
  }
  .slide-logo {
    position: absolute;
    width: 60%;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%);
  }
  .label-free {
    text-transform: uppercase;
    position: absolute;
    top: 5px;
    background: $primary-red;
    padding: 3px 5px;
    border-radius: 6px;
    font-size: 12px;
    left: 5px;
  }
}

/*SLIDE SERIES*/
.slide-series {
  position: relative;
  .title {
    align-items: center;
    h3 {
      text-transform: uppercase;
      font-weight: bold;
    }
    a {
      font-size: 13px;
      line-height: 20px;
      min-width: 128px;
      padding-top: 6px;
      padding-bottom: 6px;
    }
  }
  .slide-container {
    width: 100%;
    img {
      width: 100%;
    }
    .slide-image {
      position: relative;
      .slide-logo {
        position: absolute;
        width: 35%;
        bottom: 5px;
        left: 5px;
      }
      .label-free {
        text-transform: uppercase;
        position: absolute;
        top: 5px;
        background: $primary-red;
        padding: 3px 5px;
        border-radius: 6px;
        font-size: 12px;
        left: 5px;
      }
      .slide-play {
        position: absolute;
        border: solid 2px $primary-red;
        border-radius: 50%;
        display: flex;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        background-color: rgba(0, 0, 0, 0.4);
        padding: 10px;
        ion-icon {
          font-size: 32px;
        }
      }
    }
    .slide-title {
      display: flex;
      /* justify-content: center; */
      align-items: center;
      margin-top: 10px;
      span.title-play {
        ion-icon {
          font-size: 24px;
          border: 2px solid $primary-red;
          border-radius: 50%;
          padding: 5px;
          margin-right: 10px;
        }
      }
    }
  }
  .swiper-button-next,
  .swiper-button-prev {
    top: calc(50% - 25px);
  }
  .separator {
    position: absolute;
    bottom: -1rem;
    width: 100%;
    left: -2rem;
  }
}

/*SECTION DOWNLOAD*/
.section-download {
  .container {
    align-items: center;
    .section-text {
      width: 50%;
      .qr-code {
        justify-content: space-between;
      }
      .app-images {
        justify-content: space-between;
      }
    }
    .section-image {
      width: 50%;
      margin-left: 10px;
      img {
        padding: 10px;
        margin: 0 auto;
      }
    }
  }
}

/*SECTION DONATION*/
.section-donation {
  .container {
    align-items: center;
    .text-donation {
      width: 60%;
    }
    .button-donation {
      width: 40%;
      text-align: right;
    }
  }
}

/*SECTION FOOTER*/
.section-footer {
  .container {
    justify-content: space-between;
  }
}

/*DEFAULT PAGE*/
.default-page {
  max-width: 1180px;
  h1,
  h2 {
    text-transform: uppercase;
    font-weight: bold;
  }
}

/*ESTRENOS PAGE*/
.container-estrenos {
  .wrap-estrenos {
    .wrap-thumb {
      width: 50%;
      margin-right: 10px;
    }
    .info-estrenos {
      width: 50%;
      margin: 10px;
      .first-section {
        .actions {
          ion-icon {
            font-size: 22px;
          }
        }
      }
    }
  }
  .wrap-estrenos:not(:last-child) {
    border-bottom: 1px solid;
    padding-bottom: 1rem;
  }
}

/*SERIES PAGE*/
.container-series {
  .container-video {
    width: 100%;
  }
}

/*LOGIN PAGE*/
.container-login {
  .facebook {
    ion-icon {
      font-size: 24px;
      margin-right: 5px;
      color: #067aea;
    }
  }
  .google {
    ion-icon {
      font-size: 24px;
      margin-right: 5px;
      color: rgb(255, 61, 0);
    }
  }
  .text-other-social {
    left: 50%;
    transform: translate(-50%, -50%);
    background: #0d0e10;
    padding: 0 20px;
  }
  button {
    font-size: 14px;
  }
}

/*SPONSORS PAGE*/
.container-sponsors {
  .btn-default-b-red {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .video-wrapper {
    .video-background {
      width: 100%;
      height: calc(100vh);
      min-height: 600px;
      max-height: 56vw;
    }
    .wrap-container {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
    }
    .container {
      display: flex;
      align-items: center;
      height: 100%;
      .wrap-form-sponsors {
        width: 420px;
        background: rgba(0, 0, 0, 0.6);
        padding: 20px;
        border-radius: 20px;
        form {
          display: flex;
          flex-direction: column;
          p {
            font-size: 15px;
            margin-bottom: 10px;
            margin-top: 5px;
          }
        }
      }
    }
  }
  .how-do .container {
    .video {
      width: 60%;
    }
    .how-do-section {
      width: 40%;
      h3 {
        margin-bottom: 15px;
      }
      .how-do-text {
        width: 320px;
        ion-icon {
          color: $primary-red;
          margin-right: 15px;
        }
      }
    }
  }
  .banner-two {
    background-image: url(/public/images/sponsors-banner.jpg);
    background-size: cover;
    height: 100vh;
    min-height: 600px;
    background-position: bottom;
    .box {
      background: rgba(0, 0, 0, 0.6);
      border-radius: 10px;
      max-width: 800px;
      padding: 40px 60px;
      text-align: center;
      .banner-two-text {
        line-height: 1.7;
        margin-bottom: 25px;
        margin-top: 10px;
        span {
          background: $primary-red;
          font-weight: bold;
          padding: 2px 5px;
          border-radius: 10px;
          font-size: 1.5em;
        }
      }
    }
  }
  .sponsors-social-media {
    h2 {
      text-align: center;
      max-width: 800px;
      margin: 0 auto;
    }
    .container {
      .media-element {
        text-align: center;
      }
    }
    .container .media-element img {
      height: 240px;
      margin: 0 auto 10px;
    }
    .container .media-element .fallow {
      background: #ff0000;
      font-size: 32px;
      font-weight: bold;
      line-height: 25px;
      padding-top: 5px;
      width: 120px;
      border-radius: 10px;
      margin: 0 auto;
      margin-top: 5px;
    }
    .container .media-element .fallow span {
      display: block;
      font-size: 14px;
    }
  }
  .sponsors-clients {
    .swiper-wrapper {
      align-items: center;
    }
  }
}

/*DONACIONES PAGE*/
.container-donation {
  .wrap-donation {
    max-width: 800px;
    margin: 0 auto;
    box-shadow: 0px 5px 10px 0px rgba(255, 255, 255, 0.7);
    border-radius: 20px;
    &:hover {
      transform: translateY(-5px);
      box-shadow: 0px 10px 20px 2px rgba(255, 255, 255, 0.7);
    }
    .up-sec {
      background-image: url(/public/images/banner-donaciones-desk.png);
      background-position: 50%;
      background-size: cover;
      background-repeat: no-repeat;
      z-index: 0;
      height: 288px;
      padding-left: 30px;
    }
    .options {
      .wrap-options {
        .option-default select {
          min-width: 200px;
          width: 90%;
          max-width: 400px;
          color: #fff;
          background-color: #000;
          margin-left: auto;
          margin-right: auto;
          margin-top: 5px;
          appearance: none;
          display: block;
          background-image: none;
          border: 1px solid;
          word-break: normal;
        }
      }
      .wrap-select:after {
        content: ">";
        font: 17px "Consolas", monospace;
        color: #fff;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        transform: rotate(90deg);
        right: 30px;
        top: 10px;
        padding: 0 0 2px;
        position: absolute;
        pointer-events: none;
      }
    }
  }
}

/*CONTACT PAGE*/
.container-contact {
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  .button-actions {
    max-width: 600px;
    margin: 0 auto;
    justify-content: space-evenly;
    button,
    a {
      width: 100%;
      max-width: 200px;
    }
    .btn-default-b-red ion-icon,
    .btn-default-b-white ion-icon {
      padding-right: 10px;
    }
  }
  form {
    max-width: 460px;
    margin: 0 auto;
  }
}

/*PLAYER PAGE*/
.container-player,
.container-series,
.info-serie-buttons {
  .actions-buttons {
    .loading-button-section {
      width: 155px;
      button {
        width: 100%;
      }
    }
    .btn-default-b-white.loading-button {
      /* height: 38px; */
      div {
        height: 100%;
        svg {
          /* height: 100%; */
          margin-right: 0;
        }
      }
    }
  }
}

.container-player .dropdown {
  position: absolute;
  max-width: 400px;
  background-color: #000;
  border: 1px solid white;
  border-radius: 8px;
  padding: 1rem;
  overflow: hidden;
  transition: height 500ms ease;
  .buttons-select-donation {
    button {
      display: flex;
      align-items: center;
      padding: 0.25rem 0.5rem;
      border-radius: 0.25rem;
      border-width: 2px;
      width: 85px;
    }
    button.active {
      background: white;
      color: black;
      .icon-super-like {
        background-image: url(/public/images/icons/icon-super-like-negro.png);
      }
    }
  }
}
/*.container-player .dropdown:before {
  content: "";
  width: 0;
  height: 0;
  font-size: 0;
  overflow: hidden;
  display: block;
  border-width: 15px;
  border-color: #a7a7db transparent transparent transparent;
  border-style: solid dashed dashed dashed;
}*/

/*MEMBERSHIP*/
.container-member {
  .thumb-serie {
    height: auto;
    img{
      aspect-ratio: auto;
    }
  }
}
.modal-membership {
  .container-modal {
    .membership-content {
      ul li {
        margin-bottom: 3px;
      }
    }
    hr {
      width: calc(100% + 48px);
      left: -24px;
      position: relative;
      height: 2px;
      background: white;
    }
  }
  .serie-back {
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
  }
}

.modal-coins .coins-select button.active {
  background: white;
  color: black;
}

@media screen and (max-width: 1023px) {
  header {
    .container {
      margin: 0;
      max-width: 100%;
      .menu-links {
        background: rgba(0, 0, 0, 0.9);
        text-align: center;
      }
    }
  }
  /*SECTION DOWNLOAD*/
  .section-download {
    .container {
      .section-text {
        .qr-code {
          img {
            max-width: 180px;
          }
        }
        .app-images {
          img {
            max-width: 180px;
          }
        }
      }
    }
  }
  /*SPONSORS PAGE*/
  .container-sponsors {
    .video-wrapper {
      .video-background {
        height: 56vw;
        min-height: auto;
      }
      .wrap-container {
        position: relative;
        .container {
          text-align: center;
          justify-content: center;
        }
      }
    }
    .how-do .container {
      .video {
        width: 100%;
      }
      .how-do-section {
        width: 100%;
        .how-do-text {
          width: 100%;
        }
      }
    }
    .banner-two {
      background-image: url(/public/images/sponsors-banner-movil.jpg);
      height: 460px;
      min-height: auto;
    }
    .banner-two .box {
      padding: 0;
      background: transparent;
    }
  }
  /*PLAYER PAGE*/
  .container-player {
    .first-line {
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #ccc;
      button {
        flex-direction: row;
      }
    }
  }
}
@media screen and (max-width: 767px) {
  .actions-buttons {
    .btn-default-b-white {
      &:hover {
        background: inherit;
        color: inherit;
      }
    }
  }
  .container-series {
    .actions-buttons {
      flex-direction: column;
      .play-button {
        .action-video-button {
          flex-direction: row;
          border: 2px solid;
          border-color: #ff0000;
          max-width: 460px;
          margin: 0 auto;
        }
      }
    }
  }
  .action-video-button {
    flex-direction: column;
    padding: 6px;
    border: 0;
    background: transparent;
    ion-icon {
      font-size: 24px;
    }
    div {
      text-transform: capitalize;
      font-weight: normal;
    }
  }
  .home-banner {
    height: calc(100vh - 73px);
    max-height: 152vw;
    .wrap-info-serie {
      height: 100%;
      .info-serie {
        width: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;
        height: 100%;
        padding-bottom: 20px;
        .info-serie-buttons {
          width: 100%;
          max-width: 100%;
          .info-serie-categ {
            justify-content: center;
            .estreno-capitulo {
              border: 1px solid $primary-red;
            }
          }
          .actions-buttons-mob {
            display: flex;
            justify-content: space-between;
            .btn-mob {
              display: flex;
              flex-direction: column;
              align-items: center;
              ion-icon {
                font-size: 24px;
              }
            }
          }
        }
        img {
          max-width: 260px;
          margin: 0 auto;
        }
        p {
          text-align: center;
        }
      }
    }
    img.img-banner {
      aspect-ratio: 40/61;
    }
  }
  .info-serie-buttons {
    .actions-buttons {
      .btn-default-b-white.loading-button {
        height: 45px;
        padding: 5px;
      }
      button {
        padding: 0;
      }
    }
  }
  .text-promo {
    font-size: 18px;
  }

  /*SLIDE SERIES*/
  .slide-series .swiper-button-next,
  .slide-series .swiper-button-prev {
    display: none;
  }
  /*SECTION DOWNLOAD*/
  .section-download {
    .container {
      flex-direction: column;
      position: relative;
      .section-text {
        width: 100%;
        .app-images {
          position: relative;
          flex-direction: column;
          align-items: center;
          width: 100%;
          margin-top: 15px;
          img {
            max-width: inherit;
          }
        }
      }
      .section-image {
        width: 100%;
        margin-left: 0;
        img {
          padding: 20px;
        }
      }
    }
  }
  /*SECTION DONATION*/
  .section-donation {
    .container {
      flex-direction: column;
      .text-donation {
        width: 100%;
      }
      .button-donation {
        width: 100%;
        text-align: center;
      }
    }
  }
  /*SECTION FOOTER*/
  .section-footer {
    .container {
      ul {
        justify-content: center;
      }
    }
  }
  /*ESTRENOS PAGE*/
  .container-estrenos {
    .wrap-estrenos {
      .wrap-thumb {
        width: 100%;
        margin: 0;
      }
      .info-estrenos {
        width: 100%;
        margin: 0;
      }
    }
  }
  /*SERIE PAGE*/
  %btn-default {
    ion-icon {
      padding-right: 0px;
    }
  }
  /*SPONSORS PAGE*/
  .container-sponsors {
    .video-wrapper {
      .video-background {
        height: 56vw;
        min-height: auto;
      }
      .wrap-container {
        position: relative;
        .container {
          text-align: center;
          justify-content: center;
        }
      }
    }
    .sponsors-social-media .container .media-element img {
      padding-top: 0px !important;
      height: auto;
    }
  }
  /*DONACIONES PAGE*/
  .container-donation {
    .wrap-donation {
      .up-sec {
        background-image: url(/public/images/banner-donaciones-mob.png);
      }
    }
  }
  /*CONTACT PAGE*/
  .container-contact .button-actions {
    align-items: center;
    flex-direction: column;
    .btn-default-b-red ion-icon,
    .btn-default-b-white ion-icon {
      padding-right: 10px;
    }
  }
  /*PLAYER PAGE*/
  .container-player,
  .container-series {
    .actions-buttons {
      .loading-button-section {
        width: 75px;
      }
      .btn-default-b-white.loading-button {
        /*  height: 56px; */
        height: 100%;
        width: 100%;
        padding: 8px;
        /* width: 88px; */
      }
    }
    .dropdown 
    {
      padding: 1rem 0.75rem;
      .buttons-select-donation button{
        width: 75px;
      }
    }
  }
  /*MEMBERSHIP*/
  .modal-membership {
    .container-modal hr {
      width: calc(100% + 24px);
      left: -12px;
      position: relative;
      height: 2px;
      background: white;
    }
    .serie-back {
      width: calc(100% + 1.5rem);
      margin-left: -0.75rem;
    }
  }
}
@media screen and (max-width: 640px) {
  /*SLIDE SERIES*/
  .slide-series {
    .title {
      .btn-default-b-white {
        padding-left: 0.75rem;
        padding-right: 0.75rem;
        padding-top: 0.35rem;
        padding-bottom: 0.35rem;
      }
    }
    .swiper {
      /*FIX SLIDE 100%*/
      /* left: -0.75rem;
      padding-left: 0.75rem;
      width: calc(100% + 1.5rem); */
    }
  }
}
@media screen and (max-width: 520px) {
  header {
    .container {
      .menu-logo img {
        height: 30px !important;
      }
    }
  }
}
@media screen and (max-width: 380px) {
  .container-player,
  .container-series {
    .actions-buttons {
      .loading-button-section {
        width: 60px;
      }
      .font-sm-buttons {
        font-size: 10px;
      }
    }
  }
  .container-player .dropdown .buttons-select-donation button {
    width: 70px;
  }
}
@media screen and (max-width: 360px) {
  .home-banner
    .wrap-info-serie
    .info-serie
    .info-serie-buttons
    .actions-buttons-mob {
    .btn-default-b-red {
      font-size: 13px;
    }
    /* .btn-mob {
      font-size: 12px;
    } */
  }
  .container-sponsors .video-wrapper .container .wrap-form-sponsors form p {
    font-size: 13px;
  }
}
@media screen and (min-width: 1200px) {
  /*SECTION DOWNLOAD*/
  .section-download {
    .container {
      max-width: 1000px;
    }
  }
  /*SECTION DONATION*/
  .section-donation {
    .container {
      max-width: 1180px;
    }
  }
  /*SECTION FOOTER*/
  .section-footer {
    .container {
      max-width: 1180px;
      align-items: center;
    }
  }
}
@media screen and (min-width: 2000px) {
  .container-sponsors .video-wrapper .video-background {
    height: auto;
  }
  .container-sponsors .banner-two {
    max-height: 920px;
  }
}

.cookies-consents {
  font-size: 14px;
  line-height: 18px;
  background: #fff !important;
  color: #374151 !important;
  left: inherit !important;
  display: block !important;
  box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
  max-width: 400px;
  width: auto !important;
  right: 0;
  margin: 15px;
  border-radius: 2px;
}
